import { ChangeDetectionStrategy, Component, Input, TemplateRef } from '@angular/core';
import { EmployeeSelectItemViewModel } from '@app/shared/schedule-employee/employee-select-items.selector';

import { PlanType } from '../../../../../+authenticated/+reports/shared/subscriptions/subscription.model';
import { trackByIdFn } from '../../../../trackby';

@Component({
  selector: 'sb-employee-select',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './sb-employee-select.component.html',
})
export class SbEmployeeSelectComponent {
  @Input()
  public employee: EmployeeSelectItemViewModel;
  @Input()
  public date: string;
  @Input()
  public assignTmp: TemplateRef<any>;

  public planType = PlanType;

  public trackById = trackByIdFn;
}
