import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'select-schedule-conflict',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './select-schedule-conflict.component.html',
})
export class SelectScheduleConflictComponent {
  @Input()
  public shift: string;
  @Input()
  public isFirst: boolean;
}
