import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'select-availability-conflict',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './select-availability-conflict.component.html',
})
export class SelectAvailabilityConflictComponent {
  @Input()
  public availability: { available?: boolean; description?: string };
  @Input()
  public isFirst: boolean;
}
