export enum OpenShiftStatusFilterTypes {
  REQUESTED = 'Requested',
  PENDING = 'Pending',
  DECLINED = 'Declined',
  ASSIGNED = 'Assigned',
  NOT_INVITED = 'Not invited',
  INCOMPATIBLE_EMPLOYEES = 'Incompatible employees',
}

export enum OpenShiftStatusFilterColorMap {
  REQUESTED,
  PENDING,
  DECLINED,
  ASSIGNED,
}
