<div [style.height.px]="height">
  <p-table
    [value]="filteredRows"
    styleClass="p-datatable-sm openshift-assign sb-table-body-white"
    sortField="team.order"
    sortMode="single"
    [scrollable]="true"
    responsiveLayout="scroll"
    rowGroupMode="subheader"
    groupRowsBy="team.name"
    scrollHeight="flex"
    dataKey="id"
    [(selection)]="selected"
    (selectionChange)="selectionChange($event)"
  >
    <ng-template pTemplate="emptymessage">
      <content-state
        class="content-state-margin block"
        [isEmpty]="true"
        icon="employees"
        size="extra-small"
        content="{{ 'No employees found, try changing the filters' | translate }}"
        direction="column"
      >
      </content-state>
    </ng-template>
    <ng-template pTemplate="caption">
      <div class="flex gap-4">
        <div class="w-full">
          <sb-search-input [formControl]="searchCtrl" [placeholder]="'Search' | translate"></sb-search-input>
        </div>

        <button [sbDropdownToggle]="menu" sb-button color="secondary" type="button">
          <sb-icon [name]="'filter'"></sb-icon>
          {{ 'Filters' | translate }}
          <sb-icon iconPositionEnd [name]="'chevron-down'"></sb-icon>
        </button>
        <ng-template #menu>
          <div sbDropdownMenu>
            <div sbDropdownItemCheckbox *ngFor="let filter of filters; trackBy: trackBy">
              <sb-checkbox [formControl]="filterGroup.get(filter.value)">
                {{ filter.name }}
              </sb-checkbox>
            </div>
          </div>
        </ng-template>
      </div>
      <div class="shifts-left-header mb-2 mt-4">
        <span class="font-normal">{{ 'Shifts left to be assigned:' | translate }}</span
        >&nbsp;{{ instancesRemaining - selected?.length }}
      </div>
    </ng-template>
    <ng-template pTemplate="groupheader" let-employee>
      <tr pRowGroupHeader>
        <td class="pl-4">
          <span class="font-semibold">{{ employee.team.name }}</span>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-employee>
      <tr
        [ngClass]="{
          'bg-error-100':
            !!employeeOptions[employee.id]?.employability && !employeeOptions[employee.id]?.employability?.employable,
          lacksRequiredSkills: employeeOptions[employee.id]?.onlyLackSkills,
          'bg-success-100':
            employeeOptions[employee.id].employability?.employable &&
            employeeOptions[employee.id].employability?.details?.availability?.available
        }"
        class="employee-table-row cursor-pointer pl-4"
        (click)="selectEmployee($event, employee)"
        [style.min-height.px]="50"
      >
        <td [style.max-width.px]="20" class="justify-content-start mr-2 flex flex-col px-0 pt-4">
          <sb-checkbox
            [checked]="selectedEntities[employee.id]"
            [disabled]="checkboxDisabled && !selectedEntities[employee.id]"
          >
          </sb-checkbox>
        </td>
        <td>
          <sb-employee-select
            class="block w-full"
            [date]="date"
            [employee]="employeeOptions[employee.id]"
            [assignTmp]="assignTmp"
          ></sb-employee-select>
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>
