import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { trackByIdFn } from '../../../../../../trackby';

@Component({
  selector: 'select-skill-conflict',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './select-skill-conflict.component.html',
})
export class SelectSkillConflictComponent {
  @Input()
  public skills: string[];

  public trackById = trackByIdFn;
}
